import React, { useState } from "react";
import Debug from "debug";
import MaterialTable, {
  MTableCell,
  MTableToolbar,
  MTableBodyRow,
  MTableBody,
  Column
} from "material-table";
import Icon from "@material-ui/core/Icon";
import Box, { BoxProps } from "@material-ui/core/Box";
import NavPdfDownloader from "../components/NavPdfDownloader";

import _ from "lodash";
import { useLang } from "../context/lang";
import { useStore } from "../context/store";
import { EditPanel } from "../components/EditPanel";
import EditField from "../components/EditField";
import { Loader } from "../components/Loader";
import { IMovement, ITrip, Movement } from "navision-proxy-api/@types/terminal";
import { IVisibilitySetting, useViewSettings } from "../context/viewSettings";
import { useTripsApiActions } from "../hooks/useTripsApiActions";
import { Paper, Typography } from "@material-ui/core";
import VirtualizedMTableBody from "../components/VirtualizedMTableBody";
import InfiniteScrollTableBody from "../components/InfiniteScrollTableBody";
import { Line } from "@react-pdf/renderer";

const debug = Debug("trip-table");
/** Method for getting an id for the input  */
export const getFieldId = (
  tripNr: string,
  lineIndex: number,
  fieldName: string
) => `${tripNr}_${lineIndex}_${fieldName}`;

const getLineId = (movement: Movement) =>
  `${movement.ShipmentNr}_${movement.MovementNr}`;

const isValueEmpty = (value: any) =>
  value == "0" ||
  value == undefined ||
  value == 0 ||
  value?.length == 0 ||
  value == null ||
  value == "";

interface ITripTableProps {
  trip: ITrip;
  editable?: boolean;
  onOpen?: () => void;
  saveTrip?: (trip: ITrip) => void;
  refreshApprovedSummary?: (trip: ITrip) => void;
  /** Always save trip in the global trips store after update the line
   * which will trigger rerender of everything
   * instead of just saving it locally in this component.
   * Used for alerts AlertedMovementsTable
   */
  alwaysSaveOnUpdate?: boolean;
  alertApprovedMode?: boolean;
  close?: () => void;
  /** hides all actions in the trip panel */
  hideTripActions?: boolean;
  hidePdfAction?: boolean;
}

const TripTable = React.memo(
  ({
    trip,
    editable,
    onOpen,
    saveTrip,
    refreshApprovedSummary,
    alwaysSaveOnUpdate,
    hideTripActions,
    hidePdfAction,
    alertApprovedMode,
    close
  }: ITripTableProps) => {
    const { updateApproveTripLines, updateTrip, unApproveLines } =
      useTripsApiActions();
    const { fields } = useViewSettings();

    console.debug("trip table rerender", trip);

    const { editedTrips, removeFromEditedTrips } = useStore();
    const { t } = useLang();

    /** We want to store values as a ref instead of the state to not trigger re-update
     * Input are uncontrolled, but the information from the is always storing here
     */
    const lines = React.useRef<any[]>(
      trip.Lines.map((line: any) => ({
        ...line,
        Packages: { ...line.Packages }
      }))
    );

    // /** Apply changes of the trip from above */
    // React.useEffect(() => {
    //   debug("trip update effect in trip table", trip);

    //   // lines.current = trip.Lines.map((line: any) => ({
    //   //   ...line,
    //   //   Packages: { ...line.Packages }
    //   // }));
    // }, [trip]);

    /** Call trip context to save the trip globally
     * Triggers the rerender of everything
     * We dont want to have trip context directly here because it will rerender too much
     */
    const handleSaveTrip = () => {
      const newTrip = {
        ...trip,
        //Lines: lines.current
        Lines: lines.current.map((l, i) => ({
          ...l,
          isNew: true
        }))
      };
      if (saveTrip) saveTrip(newTrip);
    };

    /** run onmount hook */
    React.useLayoutEffect(() => {
      if (onOpen) {
        setTimeout(() => {
          console.debug("trip table:running on open");
          onOpen();
        }, 1);
      }

      return () => {
        console.debug("trip table umount");
        //bad solution - triggeres infinite loop
        //it probably saves the view in some case, but when handleSavePorts it triggers infinitive loop
        //handleSaveTrip();
      };
    }, []);

    /** @deprecated - always should be true: Lately two different states was used but it slow. TODO: remove this state */
    const [editing, setEditing] = React.useState(true);

    const [loading, setLoading] = React.useState(false);

    const [selectedMovements, setSelectedMovements] =
      React.useState<Movement[]>();

    //for updating row with click(onClick losses the focus of active row so we need to store it)
    const lastActiveItemIndex = React.useRef<number>(0);

    const tableContainerRef = React.createRef<any>();

    //const editButtonRef = React.useRef<any>(null);

    const enablePaging = trip.Lines.length > 20;

    const saveLineField = (index: number, field: string, value: any) => {
      const newValue = value === "" || value == null ? "0" : value;
      const splitedField = field.split(".");
      if (lines.current) {
        if (splitedField[0] === "Packages") {
          lines.current[index]["Packages"][splitedField[1]] = newValue;
        } else {
          lines.current[index][field] = newValue;
        }
      }
    };

    /** Update input field with the current line ref value */
    const forceUpdateLineFieldInput = (index: number, field: string) => {
      const inputElement = document.getElementById(
        getFieldId(trip.PartialTripNr, index, field)
      ) as HTMLInputElement;
      if (inputElement && lines.current) {
        inputElement.value = lines.current[index][field];
      }
    };

    const handleUpdateTrip = async () => {
      console.debug("updating trip");
      if (lines.current) {
        // we want update the whole trip
        const updatedLines = lines.current.map((l, i) => ({
          ...l,
          touchedDate: new Date(),
          isNew: true
        }));
        lines.current = updatedLines;

        await updateTrip({
          ...trip,
          Lines: lines.current
        });
        handleSaveTrip();
      }
    };

    const handleUpdateRow = async (index: number) => {
      if (lines.current) {
        //we want to update only one line
        const line: Movement = lines.current[index];
        focusNextInput(index);
        lines.current[index].touchedDate = new Date();
        //lines.current[index].isNew = true;

        if (alertApprovedMode) {
          lines.current[index].alertApproved = true;
        }

        //prefill gates
        if (
          !isValueEmpty(line.ActualGate) &&
          lines.current.some(
            //some of the lines has actual gate not prefiled
            ({ ActualGate }) => isValueEmpty(ActualGate)
          )
        ) {
          //fill other lines with actual gate
          lines.current.forEach((l: any, i: number, a: any[]) => {
            a[i] = {
              ...l,
              ActualGate: !isValueEmpty(l.ActualGate)
                ? l.ActualGate
                : line.ActualGate
            };

            forceUpdateLineFieldInput(i, "ActualGate");
          });

          await updateApproveTripLines(line.PartialTripNr, lines.current, [
            line
          ]);
        } else {
          //regular update
          await updateApproveTripLines(line.PartialTripNr, [], [line]);
        }

        applyStickiness();
        if (refreshApprovedSummary)
          refreshApprovedSummary({ Lines: lines.current } as ITrip);

        //save trip globalys
        if (
          (lines.current.every(l => l.touchedDate) && //all lines approved
            !editedTrips.includes(trip.PartialTripNr)) || //and edited trips needed to update
          (lines.current.some(
            //actual gates needed to update
            (line, index) => line.ActualGate !== trip.Lines[index].ActualGate
          ) &&
            lines.current.every(line => isValueEmpty(line.ActualGate))) ||
          alwaysSaveOnUpdate
        ) {
          //if (close) close();
          //save trip so it is fully approved above
          //this will trigger compute trips
          console.debug("calling save trip");
          handleSaveTrip();
          console.debug("trip saved");
        }
      }
    };

    const handleUnapproveRow = async (index: number) => {
      lines.current = lines.current.map((l, i) => ({
        ...l,
        touchedDate: i == index ? false : l.touchedDate
      }));
      debug("new lines", lines.current);
      const line = lines.current[index];
      focusNextInput(index);
      await unApproveLines([line]);
      applyStickiness();

      if (refreshApprovedSummary)
        refreshApprovedSummary({ Lines: lines.current } as ITrip);

      // //all lines are unapproved
      if (
        lines.current.every(l => !l.touchedDate) &&
        editedTrips.includes(trip.PartialTripNr)
      ) {
        handleSaveTrip();
      }
      // await untouchShipments([
      //   lines.current.find((line: any) => line.ShipmentNr == ShipmentNr)
      // ]);
    };

    const handleUnapproveTrip = async () => {
      lines.current = lines.current.map(line => ({
        ...line,
        touchedDate: false
      }));

      //refreshApprovedSummary({ Lines: lines.current });

      await unApproveLines(lines.current);
      applyStickiness();
      //removeFromEditedTrips(trip.PartialTripNr);
      if (editedTrips.includes(trip.PartialTripNr)) {
        handleSaveTrip(); //triggers remove from saved trip
      }
    };

    //arrow navigation
    React.useEffect(() => {
      const handleKeyDown = (e: any) => {
        if (document.activeElement) {
          //event is inside this table
          const inputs: any[] = Array.from(
            document.getElementsByClassName(
              `navigatableField${trip.PartialTripNr.replaceAll(" ", "__")}`
            )
          );

          const activeIndex = inputs.indexOf(document.activeElement);

          const activeTag = document.activeElement.tagName;

          const findNextField = (e: any) => {
            if (document.activeElement) {
              return (
                e.getAttribute("field") ===
                  document.activeElement.getAttribute("field") &&
                e.getAttribute("editable") === "always"
              );
            }
          };

          //navigating between edit button and search field
          if (
            tableContainerRef.current &&
            tableContainerRef.current.contains(e.target)
          ) {
            const searchButton =
              tableContainerRef.current.getElementsByTagName("INPUT")[0];

            if (activeTag === "BUTTON" && e.key === "ArrowRight") {
              searchButton.focus();
            } else if (
              e.key === "ArrowLeft" &&
              document.activeElement == searchButton
            ) {
              tableContainerRef.current
                .getElementsByTagName("BUTTON")[0]
                .focus();
            }
          }

          if (inputs.length > 0 && activeIndex > -1 && activeTag !== "LI") {
            switch (e.key) {
              case "ArrowRight":
                e.preventDefault();
                inputs[(activeIndex + 1) % inputs.length].focus();
                break;
              case "ArrowLeft":
                e.preventDefault();
                inputs[
                  activeIndex === 0 ? inputs.length - 1 : activeIndex - 1
                ].focus();

                break;
              case "ArrowUp":
                e.preventDefault();
                if (activeTag !== "DIV") {
                  const nextElement = inputs
                    .slice(0, activeIndex)
                    .reverse()
                    .find(findNextField);
                  if (nextElement) {
                    nextElement.focus();
                  } else {
                    inputs.reverse().find(findNextField).focus();
                  }
                }
                break;
              case "ArrowDown":
                e.preventDefault();
                if (activeTag !== "DIV") {
                  const nextElement = inputs
                    .slice(activeIndex + 1)
                    .find(findNextField);
                  if (nextElement) {
                    nextElement.focus();
                  } else {
                    inputs.find(findNextField).focus();
                  }
                }
                break;
            }
          }
        }
      };
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }, []);

    const focusNextInput = (originIndex: number) => {
      if (lastActiveItemIndex.current !== undefined) {
        if (lastActiveItemIndex.current == originIndex) {
          //focus next one
          document
            .getElementById(
              `${trip.PartialTripNr}_${
                lastActiveItemIndex.current + 1
              }_LoadedQty`
            )
            ?.focus();
        } else {
          //stay on current focus
          document
            .getElementById(
              `${trip.PartialTripNr}_${lastActiveItemIndex.current}_LoadedQty`
            )
            ?.focus();
        }
      }
    };

    // //set tabindexes for navigation
    // React.useEffect(() => {
    //   // if (tableContainerRef.current) {
    //   //   [...tableContainerRef.current.getElementsByTagName("INPUT")].forEach(
    //   //     el => {
    //   //       el.setAttribute("tabindex", "-1");
    //   //     }
    //   //   );
    //   //   [...tableContainerRef.current.getElementsByTagName("TH")].forEach(el => {
    //   //     el.firstElementChild.setAttribute("tabindex", "-1");
    //   //     if (el.firstElementChild.firstElementChild) {
    //   //       el.firstElementChild.firstElementChild.setAttribute("tabindex", "-1");
    //   //     }
    //   //   });
    //   // }
    // });

    const _Box: React.ComponentType<BoxProps & { ref?: any }> = Box;

    const tableRef = React.useRef<any>();

    //stycky style to columns
    const applyStickiness = () => {
      if (tableContainerRef.current) {
        const isTablet = window.innerWidth >= 600 && window.innerWidth <= 1200;
        const isMobile = window.innerWidth < 600;
        const isDesktop = window.innerWidth > 1200;
        //global style
        const actionsHeader =
          tableContainerRef.current.getElementsByTagName("th")[1];
        actionsHeader.style.width = "170px";
        console.debug(isTablet, isMobile, isDesktop);
        if (isMobile) {
          //no stickyness for mobile
          return;
        } else if (isTablet) {
          //fixing only actions column
          if (lines.current) {
            //actions column width
            const actionsHeader =
              tableContainerRef.current.getElementsByTagName("th")[1];
            // actionsHeader.style.width = "170px";
            actionsHeader.style.position = "sticky";
            actionsHeader.style.left = 0;
            actionsHeader.style.zIndex = 12;
            actionsHeader.style.boxShadow =
              "inset -8px 0px 0px -6px rgba(224, 224, 224, 1)";

            [...tableContainerRef.current.getElementsByTagName("tr")].forEach(
              (row, rowIndex) => {
                const actionsCell = row.getElementsByTagName("td")[1];
                if (actionsCell && lines.current[rowIndex - 1]) {
                  actionsCell.style.backgroundColor = lines.current[
                    rowIndex - 1
                  ].touchedDate
                    ? "#D1E0D2"
                    : "#FFF";
                  actionsCell.style.position = "sticky";
                  actionsCell.style.left = 0;
                  actionsCell.style.zIndex = 10;
                  actionsCell.style.boxShadow =
                    "inset -8px 0px 0px -6px rgba(224, 224, 224, 1)";

                  // actionsCell.style.padding = 0;

                  // const containerElement = actionsCell.children[0];
                  // containerElement.style.width = "100%";
                  // containerElement.style.height = "100%";
                  // containerElement.style.borderRight = "1px solid black";
                }
              }
            );
          }
        } else if (isDesktop) {
          let hiddenColumnsBeforeFreeze = 0;
          const freezeIndex =
            //we want to freeze everything after "Country" field
            fields.findIndex(({ field, hidden }) => {
              if (hidden) {
                hiddenColumnsBeforeFreeze++;
              }
              if (field == "Country") {
                return true;
              } else {
                return false;
              }
            }) -
            hiddenColumnsBeforeFreeze +
            1 +
            2; //for select and actions column

          //freeze headers
          const stickyHeaders = [
            ...tableContainerRef.current.getElementsByTagName("th")
          ].slice(0, freezeIndex);

          //sticky columns takes to much space
          //and there is no space to scroll unfrozen columns
          const stickyWidth = stickyHeaders.reduce(
            (acc, el) => acc + el.offsetWidth,
            0
          );

          let currentPosition = 0;
          stickyHeaders.forEach((header, index) => {
            header.style.position = "sticky";
            header.style.left = `${currentPosition}px`;
            header.style.zIndex = 12;
            currentPosition += header.offsetWidth;
            header.style.boxShadow = "unset"; //remove previously drawn border
            if (index == stickyHeaders.length - 1) {
              //last element
              header.style.boxShadow =
                "inset -8px 0px 0px -6px rgba(224, 224, 224, 1)";
            }
          });

          let tooWide = false;
          if (stickyWidth + 400 > window.innerWidth) {
            tooWide = true; //no freezing
          }
          console.log(tooWide, "tooWide");
          if (tooWide) {
            const allStickyHeaders = [
              ...tableContainerRef.current.getElementsByTagName("th")
            ];
            allStickyHeaders.forEach((header, index) => {
              //cleanup
              header.style.position = "unset";
              header.style.left = `unset`;
              header.style.boxShadow = "unset";
            });
          }

          //apply stickyness for table cells
          [...tableContainerRef.current.getElementsByTagName("tr")].forEach(
            (row, rowIndex) => {
              const stickyCells = [...row.getElementsByTagName("td")].slice(
                0,
                freezeIndex
              );

              currentPosition = 0;
              stickyCells.forEach((stickyCell, index) => {
                if (stickyCell && lines.current[rowIndex - 1]) {
                  // debug(stickyCell, lines.current[rowIndex - 1]);

                  stickyCell.style.backgroundColor = lines.current[rowIndex - 1]
                    .touchedDate
                    ? "#D1E0D2"
                    : "#FFF";

                  if (tooWide) {
                    //cleanup
                    stickyCell.style.position = "unset";
                    stickyCell.style.left = `unset`;
                    stickyCell.style.boxShadow = "unset";
                  } else {
                    stickyCell.style.position = "sticky";
                    stickyCell.style.left = `${currentPosition}px`;

                    stickyCell.style.zIndex = 11;
                    stickyCell.style.boxShadow = "unset"; //remove previously drawn border

                    if (index == stickyCells.length - 1) {
                      stickyCell.style.boxShadow =
                        "inset -8px 0px 0px -6px rgba(224, 224, 224, 1)";
                    }
                  }

                  currentPosition += stickyCell.offsetWidth;
                }
              });
            }
          );
        }
      }
    };
    React.useLayoutEffect(() => {
      window.requestAnimationFrame(() => applyStickiness());
    });
    return (
      <_Box display="contents" ref={tableContainerRef}>
        <MaterialTable
          columns={fields.filter(({ field }: any) => {
            //filter packages that are not editable for all of the lines

            if (field.includes("Packages.") && lines.current) {
              if (
                lines.current.every(
                  line => line.Packages?.[field.split(".")[1]] === undefined
                )
              ) {
                return false;
              }
            }
            return true;
          })}
          tableRef={tableRef}
          data={lines.current || []}
          options={{
            //actionsCellStyle: { width: 200 },
            headerStyle: { zIndex: 11 },
            selection: true,
            draggable: false,
            // toolbar: false,
            showTextRowsSelected: false,
            showTitle: true,
            tableLayout: "fixed",
            doubleHorizontalScroll: false,
            //minBodyHeight: "calc( 100vh - 280px)",
            maxBodyHeight: "calc( 100vh - 280px)",
            emptyRowsWhenPaging: false,
            pageSize: 5,
            pageSizeOptions: [20],
            paging: false,
            sorting: true,
            showFirstLastPageButtons: false
            // not working correct with virtualized body
            // fixedColumns: {
            //   left: 2
            //   //right: 1
            // }
          }}
          onSelectionChange={movements => {
            setSelectedMovements(movements);
          }}
          onChangePage={() => {
            if (onOpen) onOpen();
          }}
          actions={[
            {
              /**
             * //empty values will be overwriten on Row Component customizing
            // because of https://github.com/mbrn/material-table/issues/676#issuecomment-629389985
            
             */
              name: "downloadPdf",
              icon: () => {},
              position: "row"
              //onClick: (e, data) => setBookingForRender(data)
            } as any,
            {
              name: "undo",
              icon: () => {},
              tooltip: t("undoRow"),
              //onClick: (e: any, { ShipmentNr }: any) => resetChanges(ShipmentNr),
              hidden: false, //value will be overwriten on Row Component customizing
              position: "row"
            } as any,
            {
              name: "updateRow",
              icon: () => {},
              //tooltip: t("updateRow"),
              hidden: false, //value will be overwriten on Row Component customizing
              position: "row"
            } as any
          ]}
          title={
            hideTripActions ? (
              ""
            ) : (
              <EditPanel
                trip={trip}
                updateTrip={handleUpdateTrip}
                handleUnapproveTrip={handleUnapproveTrip}
                tripTableLoading={loading}
                selectedMovements={selectedMovements}
              />
            )
          }
          localization={{
            body: {
              editTooltip: t("edit")
            },
            header: {
              actions: t("actions")
            },
            pagination: {
              firstTooltip: t("firstRow"),
              previousTooltip: t("previousRow"),
              nextTooltip: t("nextRow"),
              lastTooltip: t("lastRow"),
              labelRowsSelect: t("rows")
            },
            toolbar: {
              searchTooltip: t("searchTable"),
              searchPlaceholder: t("searchTable")
            }
          }}
          components={{
            // Body: props => {
            //   return <VirtualizedMTableBody {...props} />;
            // },
            Body: (
              bodyProps //infinite scroll body
            ) => (
              <InfiniteScrollTableBody
                {...bodyProps}
                scrollWidth={undefined}
                tableRef={tableRef}
                onRender={onOpen}
              />
            ),
            Cell: props => (
              <TripTableCell
                mtProps={props}
                editing={editing}
                lastActiveItemIndex={lastActiveItemIndex}
                saveLineField={saveLineField}
                trip={trip}
                lines={lines}
              />
            ),
            //Cell: props => <div>{props.value}</div>,
            Row: props => (
              <TripTableRow
                mtProps={props}
                handleUpdateRow={handleUpdateRow}
                handleUnapproveRow={handleUnapproveRow}
                defaultApproveStatus={Boolean(
                  editedTrips.includes(trip.PartialTripNr) ||
                    props.data.touchedDate
                )}
                tableContainerRef={tableContainerRef}
                hidePdfAction={hidePdfAction}
              />
            )
          }}
        />
      </_Box>
    );
  },
  (prevState, nextState) => {
    return (
      prevState.trip.PartialTripNr == nextState.trip.PartialTripNr &&
      //Actual Gate and touched can be changed from above so we have to check on it
      prevState.trip.Lines.every(
        (line: any, i: number) =>
          line?.ActualGate == nextState.trip.Lines[i]?.ActualGate &&
          line?.touchedDate == nextState.trip.Lines[i]?.touchedDate &&
          line?.ShipmentNr == nextState.trip.Lines[i]?.ShipmentNr //shipment nr can be changed
      ) && //movements can be removed
      prevState.trip.Lines.length == nextState.trip.Lines.length
    );
  }
);

interface ITripsRowProps {
  mtProps: any;
  handleUpdateRow: (index: number) => Promise<void>;
  defaultApproveStatus: boolean;
  handleUnapproveRow: (index: number) => Promise<void>;
  tableContainerRef: React.RefObject<any>;
  hidePdfAction?: boolean;
  /** onApprove start callback */
  // focusNextInput?: () => void;
  // lastActiveItemIndex: React.MutableRefObject<number>;
}

const TripTableRow = React.memo(
  ({
    mtProps,
    handleUpdateRow,
    handleUnapproveRow,
    defaultApproveStatus,
    tableContainerRef,
    hidePdfAction
  }: ITripsRowProps) => {
    const propsCopy = { ...mtProps };
    /** externally Uncontrolled input of approved state */
    const [isApproved, setIsApproved] = React.useState(defaultApproveStatus);

    debug("propsCopy", propsCopy);

    propsCopy.options.rowStyle = isApproved
      ? { backgroundColor: "#D1E0D2" }
      : { backgroundColor: "#FFF" };

    // React.useEffect(() => {
    //   // debug(
    //   //   tableContainerRef.current.getElementsByTagName("tr")[
    //   //     propsCopy.index - 1
    //   //   ]?.style
    //   // );
    //   const rowRef =
    //     tableContainerRef.current.getElementsByTagName("tr")[
    //       propsCopy.index - 1
    //     ];
    //   if (rowRef && rowRef.style) {
    //     rowRef.style.backgroundColor = isApproved ? "#D1E0D2" : "#FFF";
    //   }
    // }, [isApproved]);

    const [approveLoading, setApproveLoading] = React.useState(false);
    const [unaproveLoading, setUnapproveLoading] = React.useState(false);

    //ENTER PRESS
    React.useEffect(() => {
      const handleKeyPress = (event: any) => {
        if (
          event.key === "Enter" &&
          (event.target.getAttribute("tripid") == mtProps.data.PartialTripNr ||
            event.target.getAttribute("tripid") == "Alerted Trip") //always run for alerted movements trip
        ) {
          event.preventDefault();
          event.stopPropagation();
          if (
            parseInt(event.target.getAttribute("rowindex")) == propsCopy.index
          ) {
            handleApprove();
          }
        }
      };
      document.addEventListener("keydown", handleKeyPress);
      return () => {
        document.removeEventListener("keydown", handleKeyPress);
      };
    }, []);

    //approve action setup

    const handleApprove = async (index = propsCopy.index) => {
      setApproveLoading(true);
      await handleUpdateRow(index);
      setIsApproved(true);
      setApproveLoading(false);
    };

    const handleUnapprove = async () => {
      setUnapproveLoading(true);
      await handleUnapproveRow(propsCopy.index);
      setIsApproved(false);
      setUnapproveLoading(false);
    };

    //overwriding actions properties based on row data
    //doing it here since it not possible to do it in an actions array together with slection
    //https://github.com/mbrn/material-table/issues/676#issuecomment-629389985
    //download pdf action setup
    propsCopy.actions.find((a: any) => a.name === "downloadPdf").icon = () => (
      <NavPdfDownloader
        key={propsCopy.data.TripNr}
        shipmentId={propsCopy.data.ShipmentNr}
      />
    );

    propsCopy.actions.find((a: any) => a.name === "downloadPdf").hidden =
      hidePdfAction || false;

    propsCopy.actions.find((a: any) => a.name === "updateRow").icon = () => (
      <Loader loading={approveLoading}>
        <Box position={"relative"}>
          {/* using box for make larger zone of click, if it small default tooltip react but the box not*/}
          <Box
            position={"absolute"}
            left={-10}
            top={-15}
            width={70}
            height={70}
            onClick={e => {
              e.preventDefault();
              handleApprove();
            }}
          ></Box>
          <Icon fontSize="large" color="primary">
            done
          </Icon>
        </Box>
      </Loader>
    );

    //always visible
    propsCopy.actions.find((a: any) => a.name === "updateRow").hidden =
      unaproveLoading;

    // //undo action setup
    // propsCopy.actions.find((a: any) => a.name === "undo").hidden =
    //   !isApproved || approveLoading;

    propsCopy.actions.find((a: any) => a.name === "undo").icon = () =>
      !isApproved || approveLoading ? ( //hidden
        <Box width="30px" />
      ) : (
        <Loader loading={unaproveLoading}>
          <Box position={"relative"}>
            {/* using box for make larger zone of click, if it small default tooltip react but the box not*/}
            <Box
              position={"absolute"}
              left={-15}
              top={-15}
              width={60}
              height={70}
              onClick={async e => {
                e.preventDefault();
                handleUnapprove();
              }}
            ></Box>
            <Icon fontSize="large" color="error">
              undo
            </Icon>
          </Box>
        </Loader>
      );

    // React.useEffect(() => {
    //   document.addEventListener("keydown", handleKeyDown);
    //   return () => {
    //     document.removeEventListener("keydown", handleKeyDown);
    //   };
    // }, []);

    // const handleKeyDown = async (e: any) => {
    //   if (
    //     e.key === "Enter" &&
    //     document.activeElement &&
    //     document.activeElement.tagName === "INPUT"
    //   ) {
    //     (document.activeElement as any).blur();
    //     handleApprove();
    //     e.preventDefault();
    //   }
    // };
    return <MTableBodyRow {...propsCopy} />;
  },
  (prevProps, nextProps) => {
    return (
      getLineId(prevProps.mtProps.data) == getLineId(nextProps.mtProps.data) &&
      _(prevProps.mtProps.columns)
        .differenceWith(nextProps.mtProps.columns, _.isEqual)
        .isEmpty()
    );
  }
);

const TripTableCell = ({
  mtProps,
  editing,
  lastActiveItemIndex,
  saveLineField,
  trip,
  lines
}: any) => {
  //document.activeElement?.tagName !== "INPUT" && //there is no other current input focus
  const autoFocus =
    lastActiveItemIndex.current === 0 && //first item autofocus
    mtProps.rowData.tableData.id == 0 &&
    mtProps.columnDef.field === "LoadedQty";

  const id = getFieldId(
    trip.PartialTripNr,
    mtProps.rowData.tableData.id,
    mtProps.columnDef.field
  );

  const onFocus = () => {
    lastActiveItemIndex.current = mtProps.rowData.tableData.id;
  };

  if (Array.isArray(mtProps.value)) {
    //For multiple Quantity, Actual Quantity and Unit

    //edit version
    if (
      editing &&
      mtProps.columnDef.editable === "always" &&
      mtProps.value !== undefined
    ) {
      return (
        //for undefined packages
        <td
          className={`MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft`}
        >
          {mtProps.value.map((value: any, index: number) => (
            <EditField
              key={`${mtProps.rowData.tableData.id}_${mtProps.columnDef.field}`}
              id={id}
              initValue={value}
              onFocus={onFocus}
              saveField={(e: any, value: any) => {
                lastActiveItemIndex.current = mtProps.rowData.tableData.id;

                //when we need to get back unit key from lookup by value
                const getValueKey = (v: any) => {
                  if (mtProps.columnDef.lookup) {
                    return Object.keys(mtProps.columnDef.lookup).find(
                      key => mtProps.columnDef.lookup[key] === v
                    );
                  } else {
                    return v;
                  }
                };

                //create array of values
                const newValue = mtProps.value.map((v: any, i: number) =>
                  i == index ? value : getValueKey(v)
                );

                saveLineField(
                  mtProps.rowData.tableData.id,
                  mtProps.columnDef.field,
                  newValue
                );
              }}
              autoFocus={autoFocus && index == 0}
              partialTripNr={trip.PartialTripNr}
              rowIndex={mtProps.rowData.tableData.id}
              isLast={
                lines.current && lines.current.length
                  ? parseInt(mtProps.rowData.tableData.id) ===
                    lines.current.length - 1
                  : false
              }
              {...mtProps}
            />
          ))}
        </td>
      );
    } else {
      //non edit version
      return (
        <MTableCell {...mtProps} value="">
          {mtProps.value.map((value: any, index: number) => (
            <div key={index}>{value}</div>
          ))}
        </MTableCell>
      );
    }
  } else {
    //For all other non multiple fields
    if (
      editing &&
      mtProps.columnDef.editable === "always" &&
      mtProps.value !== undefined
    ) {
      return (
        //for editable packages
        <td
          className={`MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft`}
        >
          <EditField
            key={`${mtProps.rowData.tableData.id}_${mtProps.columnDef.field}`}
            id={id}
            initValue={mtProps.value}
            onFocus={onFocus}
            saveField={(e: any, value: any) => {
              lastActiveItemIndex.current = parseInt(
                e.target.getAttribute("rowIndex")
              );
              saveLineField(
                mtProps.rowData.tableData.id,
                mtProps.columnDef.field,
                value
              );
            }}
            autoFocus={autoFocus}
            partialTripNr={trip.PartialTripNr}
            rowIndex={mtProps.rowData.tableData.id}
            isLast={
              lines.current && lines.current.length
                ? parseInt(mtProps.rowData.tableData.id) ===
                  lines.current.length - 1
                : false
            }
            {...mtProps}
          />
        </td>
      );
    } else {
      return mtProps.columnDef.render ? ( //we use render function for custom rendering
        <MTableCell {...mtProps} value="" />
      ) : (
        <MTableCell {...mtProps} value="">
          <span className="search-highlightable">
            {mtProps.value === "" || mtProps.value === undefined // for actual gate // for undefined packages
              ? "-"
              : mtProps.value}
          </span>
        </MTableCell>
      );
    }
  }
};

export { TripTable };
